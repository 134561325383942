<template>
  <v-card class="feature-item" @click="handleClick">
    <v-img height="250" :src="feature.image"></v-img>
    <v-card-title>{{ feature.title }}</v-card-title>
    <v-card-text
      >Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veniam deleniti
      soluta porro quam vitae aspernatur non modi, exercitationem et, sint hic
      quis, cum distinctio aperiam iusto id. Qui, consectetur
      omnis?</v-card-text
    >
  </v-card>
</template>

<script>
export default {
  name: "FeatureListItem",
  props: ["feature"],
  methods: {
    handleClick() {
      this.$emit("select");
    },
  },
};
</script>

<style lang="scss" scoped>
.feature-item {
  cursor: pointer;
}
</style>