<template>
  <v-app
    :style="{
      backgroundImage: `url(${bgImage})`,
    }"
    style="
      background-color: transparent;
      height: 100vh;
      overflow-y: scroll;
      background-position: center;
      background-size: cover;
      background-attachment: fixed;
    "
  >
    <v-main dark>
      <v-container>
        <v-row justify="center">
          <v-col md="4" class="d-flex justify-center">
            <div class="white--text" style="font-size: 34px; font-weight: 300">
              Наши новинки!
            </div>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col
            v-for="feature in features"
            md="4"
            :key="`feature-${feature.id}`"
          >
            <feature-list-item
              :feature="feature"
              @select="onFeatureSelected(feature)"
            />
          </v-col>
        </v-row>
        <v-dialog
          :value="selectedFeature != null"
          @input="selectedFeature = null"
          :fullscreen="$vuetify.breakpoint.mobile"
          :max-width="$vuetify.breakpoint.mobile ? null : 600"
        >
          <component v-if="selectedFeature" :is="selectedFeature.cmp" />
        </v-dialog>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import FeatureListItem from "./FeatureListItem";

export default {
  name: "ExpoPage",
  components: {
    FeatureListItem,
  },
  data() {
    return {
      bgImage: "https://cdn.your-quest.com/partyon/theme/bg.jpg",
      features: [
        {
          id: "audio-chat",
          title: "Аудио Чат",
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGLVtKUrNGWMfZaS0lfxFXIYZKaJeLbHWo1g&usqp=CAU",
          cmp: () => import("./features/AudioChat"),
        },
        {
          id: "video-chat",
          title: "Видео Чат",
          image:
            "https://specials-images.forbesimg.com/imageserve/5e8f279c01879f00070407b3/960x0.jpg?cropX1=0&cropX2=6500&cropY1=330&cropY2=3987",
          cmp: () => import("./features/VideoChat"),
        },
        // {
        //   id: "picasso-masterpiece",
        //   title: "Пикассо: Мазня или Шедевр?",
        //   cmp: () => import("./features/PicassoMasterPiece"),
        // },
      ],
      selectedFeature: null,
    };
  },
  created() {},
  methods: {
    onFeatureSelected(feature) {
      this.selectedFeature = feature;
    },
  },
  computed: {},
};
</script>